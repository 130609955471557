import React from 'react';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, SocketEvents, checkPermission } from 'utils';
import { ChatService } from 'services';
import moment from 'moment';
import * as Jetemit from 'jetemit';
import { TrashIcon, EditIcon, SendIcon } from 'assets/icons';
import { WhiteImage } from 'assets/img';
import { Modal, Textarea } from 'components';
import { connect } from 'react-redux';

interface Props extends RootState {
	onClose?: any;
	item?: any;
}

class ModalViewChat extends React.Component<Props> {

	messagesEnd: any = null;
	textareaRef: any = null;

	state = {
		messages: [],
		unsubscriber: () => {},
		unsubscriberDelete: () => {},
		unsubscriberEdit: () => {},
		messages_rest: 0,
		loading: true,
		visible: false,
		form: {
			message: '',
			message_to_send: ''
		},
		item: null,
		textareaHeight: 38
	}

	componentDidMount() {
		this.load();

		this.setState({
			unsubscriber: Jetemit.on(SocketEvents.CHATS.MESSAGE,(data: any) => {
				if (data.san_id == this.props.item?.id) {
					let messages = [...this.state.messages];
					// @ts-ignore
					messages.push(data);
					this.setState({
						messages
					},() => this.scrollToBottom());
				}
			}),
			unsubscriberDelete: Jetemit.on(SocketEvents.CHATS.DELETE,(data: any) => {
				if (data.san_id == this.props.item?.id) {
					let messages = [...this.state.messages];
					const index = messages.findIndex((i: any) => i.id == data.message_id);
					if (index != -1) {
						messages.splice(index,1);
						this.setState({
							messages
						});
					}
				}
			}),
			unsubscriberEdit: Jetemit.on(SocketEvents.CHATS.EDIT,(data: any) => {
				if (data.san_id == this.props.item?.id) {
					let messages: any = [...this.state.messages];
					const index = messages.findIndex((i: any) => i.id == data.message?.id);
					if (index != -1) {
						messages[index].message = data.message?.message;
						this.setState({
							messages
						});
					}
				}
			})
		});
	}

	componentWillUnmount() {
		this.state.unsubscriber();
		this.state.unsubscriberDelete();
		this.state.unsubscriberEdit();
	}

	load = async (withoutScroll = false) => {
		const res: any = await ChatService.admin.view({
			san_id: this.props.item?.id,
			// @ts-ignore
			last_message_id: this.state.messages[0]?.id
		});
		this.setState({
			messages: [
				...res.messages,
				...this.state.messages
			],
			messages_rest: res.messages_rest || 0,
			loading: false
		},() => {
			if (!withoutScroll) {
				this.scrollToBottom();
			}
		});
	}

	scrollToBottom = () => {
		this.messagesEnd.scrollTo({
			behavior: 'smooth',
			top: this.messagesEnd.scrollHeight
		});
	}

	delete = (e: any, item: any) => {
		e.preventDefault();
		Globals.confirm('¿Desea eliminar el mensaje seleccionado?',async () => {
			await ChatService.admin.delete({
				message_id: item?.id
			});
		});
	}

	edit = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item,
			form: {
				...this.state.form,
				message: item?.message || ''
			}
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.scrollToBottom());
	}

	saveEdit = async () => {
		if (!this.state.form.message) {
			Globals.showError("No puede enviar el mensaje vacío");
			return;
		}

		await ChatService.admin.edit({
			// @ts-ignore
			message_id: this.state.item?.id,
			user_id: this.props.user?.id,
			message: this.state.form.message
		});
		this.onClose();
	}

	change = (e: any) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}

	autoGrow = (e: any) => {
	  if (e.nativeEvent.keyCode == 13) {
	  	e.preventDefault();
	  	this.send();
	  }
      this.textareaRef.style.height = "inherit";
	  this.textareaRef.style.height = `${Math.max(
	      this.textareaRef.scrollHeight,
	      38
	  )}px`;
	}

	send = async () => {
		if (!this.state.form.message_to_send) {
			return;
		}
		const message = this.state.form.message_to_send;
		await this.setState({
			form: {
				...this.state.form,
				message_to_send: ''
			}
		});
		await ChatService.admin.send({
			san_id: this.props.item?.id,
			message,
			user_id: this.props.user?.id,
			withoutLoading: true
		});
	}
	
	render() {
		const { messages, visible } = this.state;

		return (
			<>
				<Modal
		          className="modal-edit-message"
		          visible={ visible }
		          title="Edición de Mensaje"
		          animation={ false }
		          onClose={ () => this.onClose() }
		        >
		        	<div id="modal-edit-message">
		        		<Textarea
							rows={ 2 }
							value={ this.state.form.message }
							placeholder="Escribir"
							label="Mensaje"
							name="message"
							onChange={ (e: any) => this.change(e) } />
						<div className="text-center">
							<button className="btn btn-edit" onClick={ () => this.saveEdit() }>
								Guardar
							</button>
						</div>
		        	</div>
		      	</Modal>

				<div id="modal-view-chat">
			      	{
			      		!visible && (
			      			<>
				      			<div className="container-messages" ref={ (el) => { this.messagesEnd = el; } }>
									{
										this.state.messages_rest > 0 && (
											<div className="text-center">
												<button className="btn btn-paginate" onClick={ () => this.load(true) }>
													Cargar mensajes anteriores
												</button>
											</div>
										)
									}
									{
										messages.map((item: any) => {
											return (
												<div className="item-message">
													<img className="profile-picture" src={ item?.user?.person?.selfie ? (process.env.REACT_APP_BASE_STORAGE + item?.user?.person?.selfie) : WhiteImage } />
													<div style={{ width: '100%' }}>
														<div className="container-actions">
															<p className="name">{ item.bot == Constants.MESSAGE_BOT.YES ? 'Tu Turno Bot' : ((item.user?.name || '') + ' ' + (item.user?.lastname || '')) }</p>
															<div>
																{
																	checkPermission(Constants.MODULES.SAN,'write') && (
																		<a href="#" onClick={ (e: any) => this.edit(e,item) }>
																			<img className="edit-icon" src={ EditIcon } />
																		</a>
																	)
																}
																{
																	checkPermission(Constants.MODULES.SAN,'delete') && (
																		<a href="#" onClick={ (e: any) => this.delete(e,item) }>
																			<img src={ TrashIcon } />
																		</a>
																	)
																}
															</div>
														</div>
														<p className="message">{ item.message }</p>
														<p className="date">{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }</p>
													</div>
												</div>
											)
										})
									}

									{
										!this.state.loading && this.state.messages.length == 0 && (
											<div className="text-center">
												<p className="no-messages">No se han encontrado mensajes</p>
											</div>
										)
									}
								</div>
								{
									checkPermission(Constants.MODULES.SAN,'write') && (
										<div className="container-textarea">
											<textarea
												className="form-control"
												ref={ ref => this.textareaRef = ref }
												rows={ 1 }
												value={ this.state.form.message_to_send }
												placeholder="Escribir"
												name="message_to_send"
												style={{
													minHeight: 38,
			        								resize: "none"
												}}
												onKeyDown={ (e: any) => this.autoGrow(e) }
												onChange={ this.change } />
												<button className="btn" onClick={ () => this.send() }>
													<img src={ SendIcon } className="send-icon" />
												</button>
										</div>
									)
								}
							</>
			      		)
			      	}
				</div>
			</>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ModalViewChat);