import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Globals, Constants, Colors, checkPermission } from 'utils';
import { Table, Pagination, Tooltip, Input, DatePicker, Select, Modal } from 'components';
import { CleanIcon, PaymentIcon, ListIcon, ViewIcon } from 'assets/icons';
import { AccreditationService } from 'services';
import moment from 'moment';
import ModalView from './modal-view';
import ModalConfig from './modal-config';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

const INITIAL_STATE = {
	date: '',
	san: '',
	status: ''
}

class Payments extends React.Component<Props> {

	timer: any = undefined;

	state = {
		header: [
			'Nº San',
			'Fecha Inicio',
			'Fecha de Finalización',
			'Participantes',
			'Acreditaciones',
			'Acciones'
		],
		form: INITIAL_STATE,
		data: [],
		page: 1,
		last_page: 1,
		visible: false,
		item: null,
		visible_config: false
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Pagos Registrados',
				onBack: null
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading: boolean = false,callback = () => {}) => {
		const res: any = await AccreditationService.admin.getPayments({
			withoutLoading,
			page: this.state.page,
			...this.state.form,
			date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
		});
		this.setState({
			data: res.sanes.rows,
			last_page: res.sanes.count
		});
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			page: 1,
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	reset = () => {
		this.setState({
			form: INITIAL_STATE,
			page: 1
		},() => this.load(true));
	}

	view = (e: any, item: any) => {
		e.preventDefault();
		this.setState({
			visible: true,
			item
		});
	}

	onClose = () => {
		this.setState({
			visible: false,
			item: null
		},() => this.load(true));
	}

	open = () => {
		this.setState({
			visible_config: true
		});
	}

	onCloseConfig = () => {
		this.setState({
			visible_config: false
		});
	}
	
	render() {
		const { visible, visible_config } = this.state;

		return (
			<div id="admin-accreditations">
				<Modal
		          className="modal-view-accreditations"
		          visible={ visible }
		          title="Acreditaciones"
		          onClose={ () => this.onClose() }
		        >
		          <ModalView
		          	item={ this.state.item }
		            onClose={ () => this.onClose() } />
		      	</Modal>

		      	<Modal
		          className="modal-view-config"
		          visible={ visible_config }
		          title="Configuración"
		          onClose={ () => this.onCloseConfig() }
		        >
		          <ModalConfig
		            onClose={ () => this.onCloseConfig() } />
		      	</Modal>

				<div className="row row-tabs">
					<div className="col-md-6">
						<Link to="/admin/accreditations/payments">
							<div className="item-tab active">
								<img src={ PaymentIcon } />
								<p>Acreditación</p>
							</div>
						</Link>
					</div>
					<div className="col-md-6">
						<Link to="/admin/accreditations/history">
							<div className="item-tab">
								<img src={ ListIcon } />
								<p>Historial de Acreditaciones</p>
							</div>
						</Link>
					</div>
				</div>

				<div className="row row-filter">
					<div className="col-md-12">
						<div className="row">
							<div className="col-md-4">
								<Input
									value={ this.state.form.san }
									name="san"
									placeholder="Buscar"
									label="Nº de SAN"
									className="input-table"
									onSubmit={ () => this.load() }
									onChange={ (e: any) => {
										clearTimeout(this.timer);
										this.timer = setTimeout(() => {
											this.load(true);
										},1000);
										this.change(e); 
									} } />
							</div>
							<div className="col-md-4">
								<DatePicker
									label="Fecha de Inicio"
									placeholder="Fecha"
									onChange={ (text: string) => {
										this.change({
											target: {
												value: text,
												name: 'date'
											}
										},() => this.load(true));
									} }
									value={ this.state.form.date }
								/>
							</div>
							<div className="col-md-2">
								{
									this.state.form != INITIAL_STATE && (
										<button className="btn btn-clean" onClick={ () => this.reset() }>
											<img src={ CleanIcon } />
										</button>
									)
								}
							</div>
						</div>
					</div>
				</div>

				{
					checkPermission(Constants.MODULES.ACCREDITATIONS,'write') && (
						<button className="btn btn-credolab" onClick={ () => this.open() }>
							Configuración
						</button>
					)
				}

				<Table title="Pagos Registrados" data={ this.state.data.length } header={ this.state.header }>
					{ this.state.data.map((i: any,index: number) => {
						return (
							<tr key={ index }>
								<td>{ i.code }</td>
								<td>{ i.start_date ? moment(i.start_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.finish_date ? moment(i.finish_date).format('DD/MM/YYYY') : '-' }</td>
								<td>{ i.quantity_persons }</td>
								<td>{ i.accreditations_count }</td>
								<td style={{ 'whiteSpace': 'nowrap' }}>
									<Tooltip title="Ver">
										<a className="link-icon" href="#" onClick={ (e: any) => this.view(e,i) }>
											<img src={ ViewIcon } />
										</a>
									</Tooltip>
								</td>
							</tr>
						)
					}) }
				</Table>

				<Pagination 
					pages={ this.state.last_page } 
					active={ this.state.page }
					onChange={ async (page: number) => {
						await this.setState({
							page: page
						});
						this.load();
					} } />
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(Payments);